var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vs-row',{attrs:{"vs-justify":"center"}},[_c('vs-col',{staticClass:"pr-3",attrs:{"type":"flex","vs-justify":"center","vs-align":"center","vs-w":_vm.customerCardWidth}},[_c('vx-card',{staticClass:"m-2",attrs:{"title":_vm.actionTitle,"customTitle":_vm.custData.firstGivenName + ' ' + _vm.custData.familyName,"subtitle":_vm.custData.fileNumber}},[_c('template',{slot:"actions"},[_c('vs-button',{attrs:{"color":"dark"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v("Back")])],1),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveCustomer)}}},[_c('h5',{staticClass:"text-secondary"},[_vm._v("Basic Information")]),_c('vs-divider'),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col md-2"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Title","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"divLabel"},[_vm._v("Title")]),_c('vs-select',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"name":"title","placeholder":"Select"},model:{value:(_vm.custData.title),callback:function ($$v) {_vm.$set(_vm.custData, "title", $$v)},expression:"custData.title"}},_vm._l((_vm.titles),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.title,"text":item.title}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{attrs:{"name":"First Given Name","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"label":"First Given Name","placeholder":"Example:John","name":"FirstGivenName"},model:{value:(_vm.custData.firstGivenName),callback:function ($$v) {_vm.$set(_vm.custData, "firstGivenName", $$v)},expression:"custData.firstGivenName"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{attrs:{"name":"Family Name","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"label":"Family Name","placeholder":"Example:Lewis","name":"FamilyName"},model:{value:(_vm.custData.familyName),callback:function ($$v) {_vm.$set(_vm.custData, "familyName", $$v)},expression:"custData.familyName"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"Example:Jo","label":"Other Given Names","name":"OtherGivenNames"},model:{value:(_vm.custData.otherGivenNames),callback:function ($$v) {_vm.$set(_vm.custData, "otherGivenNames", $$v)},expression:"custData.otherGivenNames"}})],1),_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Date Of Birth")]),_c('ValidationProvider',{attrs:{"name":"Date Of Birth","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","type":"date","valueType":"YYYY-MM-DD","placeholder":"Select"},model:{value:(_vm.custData.dateofBirth),callback:function ($$v) {_vm.$set(_vm.custData, "dateofBirth", $$v)},expression:"custData.dateofBirth"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('h5',{staticClass:"text-secondary"},[_vm._v("Case Information")]),_c('vs-divider'),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{attrs:{"name":"File Number","rules":{  required: true, max: 200 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"File Number","placeholder":"Example:1001/2022","name":"FileNumber"},model:{value:(_vm.custData.fileNumber),callback:function ($$v) {_vm.$set(_vm.custData, "fileNumber", $$v)},expression:"custData.fileNumber"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"clearancetype","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"divLabel"},[_vm._v("Clearance Type")]),_c('vs-select',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"name":"clearancetype","placeholder":"Select"},model:{value:(_vm.custData.clearanceType),callback:function ($$v) {_vm.$set(_vm.custData, "clearanceType", $$v)},expression:"custData.clearanceType"}},_vm._l((_vm.clearanceTypes),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id +'|'+ item.clearanceType,"text":item.clearanceType}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"level","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"divLabel"},[_vm._v("Level")]),_c('vs-select',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"name":"level","placeholder":"Select"},model:{value:(_vm.custData.level),callback:function ($$v) {_vm.$set(_vm.custData, "level", $$v)},expression:"custData.level"}},_vm._l((_vm.levels),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id + '|' + item.level,"text":item.level}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"clearancestatus","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"divLabel"},[_vm._v("Clearance Status")]),_c('vs-select',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"name":"ClearanceStatusId","placeholder":"Select"},model:{value:(_vm.custData.clearanceStatusId),callback:function ($$v) {_vm.$set(_vm.custData, "clearanceStatusId", $$v)},expression:"custData.clearanceStatusId"}},_vm._l((_vm.clearanceStatus),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.clearanceStatus}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"department","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"divLabel"},[_vm._v("Department Type")]),_c('vs-select',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"name":"ClientDepartment","placeholder":"Select"},model:{value:(_vm.custData.clientDepartment),callback:function ($$v) {_vm.$set(_vm.custData, "clientDepartment", $$v)},expression:"custData.clientDepartment"}},_vm._l((_vm.departments),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id +'|'+ item.clientDepartment,"text":item.clientDepartment}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('vs-input',{staticClass:"w-full",attrs:{"disabled":"","label":"CSID","placeholder":"CSID","name":"VetteeId"},model:{value:(_vm.custData.vetteeId),callback:function ($$v) {_vm.$set(_vm.custData, "vetteeId", $$v)},expression:"custData.vetteeId"}})],1),_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"CaseOfficerId","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"divLabel"},[_vm._v("Case Officer")]),_c('vs-select',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"name":"CaseOfficerId","placeholder":"Select"},model:{value:(_vm.custData.caseOfficerId),callback:function ($$v) {_vm.$set(_vm.custData, "caseOfficerId", $$v)},expression:"custData.caseOfficerId"}},_vm._l((_vm.caseOfficers),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.id,"text":item.displayName}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('h5',{staticClass:"text-secondary"},[_vm._v("Other Information")]),_c('vs-divider'),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"LocationType","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"divLabel"},[_vm._v("Location Type")]),_c('vs-select',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"name":"LocationType","placeholder":"Select"},model:{value:(_vm.custData.locationType),callback:function ($$v) {_vm.$set(_vm.custData, "locationType", $$v)},expression:"custData.locationType"}},_vm._l((_vm.locationType),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item,"text":item}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Location Notes")]),_c('ValidationProvider',{attrs:{"name":"Location Notes","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"placeholder":"Location Notes","name":"LocationNotes"},model:{value:(_vm.custData.locationNotes),callback:function ($$v) {_vm.$set(_vm.custData, "locationNotes", $$v)},expression:"custData.locationNotes"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Request Received")]),_c('ValidationProvider',{attrs:{"name":"Request Received","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","valueType":"YYYY-MM-DD","type":"date","placeholder":"Select"},model:{value:(_vm.custData.requestReceived),callback:function ($$v) {_vm.$set(_vm.custData, "requestReceived", $$v)},expression:"custData.requestReceived"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Required Return Date")]),_c('ValidationProvider',{attrs:{"name":"Required Return Date","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","valueType":"YYYY-MM-DD","type":"date","placeholder":"Select"},model:{value:(_vm.custData.requiredReturnDate),callback:function ($$v) {_vm.$set(_vm.custData, "requiredReturnDate", $$v)},expression:"custData.requiredReturnDate"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Priority Due Date")]),_c('ValidationProvider',{attrs:{"name":"Priority DueDate","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","valueType":"YYYY-MM-DD","type":"date","placeholder":"Select"},model:{value:(_vm.custData.priorityDueDate),callback:function ($$v) {_vm.$set(_vm.custData, "priorityDueDate", $$v)},expression:"custData.priorityDueDate"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Priority Met:")]),_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Priority Met","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-radio',{attrs:{"vs-name":"PriorityMet","vs-value":true},model:{value:(_vm.custData.priorityMet),callback:function ($$v) {_vm.$set(_vm.custData, "priorityMet", $$v)},expression:"custData.priorityMet"}},[_vm._v("Yes")]),_c('vs-radio',{staticClass:"ml-1",attrs:{"vs-name":"PriorityMet","vs-value":false},model:{value:(_vm.custData.priorityMet),callback:function ($$v) {_vm.$set(_vm.custData, "priorityMet", $$v)},expression:"custData.priorityMet"}},[_vm._v("No")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Extension ReturnDate")]),_c('ValidationProvider',{attrs:{"name":"Extension ReturnDate","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","valueType":"YYYY-MM-DD","type":"date","placeholder":"Select"},model:{value:(_vm.custData.extensionReturnDate),callback:function ($$v) {_vm.$set(_vm.custData, "extensionReturnDate", $$v)},expression:"custData.extensionReturnDate"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Extension Approved:")]),_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Extension Approved","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-radio',{attrs:{"vs-name":"ExtensionApproved","vs-value":true},model:{value:(_vm.custData.extensionApproved),callback:function ($$v) {_vm.$set(_vm.custData, "extensionApproved", $$v)},expression:"custData.extensionApproved"}},[_vm._v("Yes")]),_c('vs-radio',{staticClass:"ml-1",attrs:{"vs-name":"ExtensionApproved","vs-value":false},model:{value:(_vm.custData.extensionApproved),callback:function ($$v) {_vm.$set(_vm.custData, "extensionApproved", $$v)},expression:"custData.extensionApproved"}},[_vm._v("No")]),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("NPHC In")]),_c('ValidationProvider',{attrs:{"name":"NPHC In","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","valueType":"YYYY-MM-DD","type":"date","placeholder":"Select"},model:{value:(_vm.custData.nphcin),callback:function ($$v) {_vm.$set(_vm.custData, "nphcin", $$v)},expression:"custData.nphcin"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('ValidationProvider',{staticClass:"w-full",attrs:{"name":"Nphcresult","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"divLabel"},[_vm._v("NPHC Result")]),_c('vs-select',{staticClass:"sm:w-full lg:w-1/1 w-full",attrs:{"name":"Nphcresult","placeholder":"Select"},model:{value:(_vm.custData.nphcresult),callback:function ($$v) {_vm.$set(_vm.custData, "nphcresult", $$v)},expression:"custData.nphcresult"}},_vm._l((_vm.NPHCResult),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item,"text":item}})}),1),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Completed")]),_c('ValidationProvider',{attrs:{"name":"Completed","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","valueType":"YYYY-MM-DD","type":"date","placeholder":"Select"},model:{value:(_vm.custData.completed),callback:function ($$v) {_vm.$set(_vm.custData, "completed", $$v)},expression:"custData.completed"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Invoiced")]),_c('ValidationProvider',{attrs:{"name":"Invoiced","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","valueType":"YYYY-MM-DD","type":"date","placeholder":"Select"},model:{value:(_vm.custData.invoiced),callback:function ($$v) {_vm.$set(_vm.custData, "invoiced", $$v)},expression:"custData.invoiced"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col"},[_c('div',{staticClass:"divLabel"},[_vm._v("Date Of Disposal")]),_c('ValidationProvider',{attrs:{"name":"Date Of Disposal","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{attrs:{"format":"YYYY-MM-DD","valueType":"YYYY-MM-DD","type":"date","placeholder":"Select"},model:{value:(_vm.custData.dateOfDisposal),callback:function ($$v) {_vm.$set(_vm.custData, "dateOfDisposal", $$v)},expression:"custData.dateOfDisposal"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row mb-2"},[_c('div',{staticClass:"vx-col w-full"},[_c('div',{staticClass:"divLabel"},[_vm._v("Reason Over Benchmark")]),_c('ValidationProvider',{attrs:{"name":"Reason Over Benchmark","rules":{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-textarea',{staticClass:"w-full",attrs:{"placeholder":"Reason Over Benchmark","name":"ReasonOverBenchmark"},model:{value:(_vm.custData.reasonOverBenchmark),callback:function ($$v) {_vm.$set(_vm.custData, "reasonOverBenchmark", $$v)},expression:"custData.reasonOverBenchmark"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('vs-divider'),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full"},[_c('vs-button',{staticClass:"mr-3 mb-2",on:{"click":function($event){$event.preventDefault();return _vm.saveCustomer.apply(null, arguments)}}},[_vm._v("Submit")])],1)])],1)]}}])})],2)],1),(_vm.isComments)?_c('vs-col',{staticClass:"pr-3",attrs:{"type":"flex","vs-justify":"center","vs-align":"center","vs-w":"4"}},[_c('vx-card',{staticClass:"m-2",attrs:{"title":"Comments"}},[_c('comments',{attrs:{"customerId":_vm.selectedCustomerId}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }