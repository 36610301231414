<template>
<vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" v-bind:vs-w="customerCardWidth" class="pr-3">
        <vx-card class="m-2" v-bind:title="actionTitle" v-bind:customTitle="custData.firstGivenName + ' ' + custData.familyName" v-bind:subtitle="custData.fileNumber">
            <template slot="actions">
                <vs-button color="dark" @click="goBack()">Back</vs-button>
            </template>
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form action="" @submit.prevent="handleSubmit(saveCustomer)">
                    <h5 class="text-secondary">Basic Information</h5>
                    <vs-divider />
                    <div class="vx-row mb-2">
                        <div class="vx-col md-2">
                            <ValidationProvider class="w-full" name="Title" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <div class="divLabel">Title</div>
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="title" v-model="custData.title" placeholder="Select">
                                    <vs-select-item :key="index" :value="item.title" :text="item.title" v-for="(item, index) in titles" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <ValidationProvider name="First Given Name" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 }" v-slot="{ errors }" :custom-messages="customMessages">
                                <vs-input class="sm:w-full lg:w-1/1 w-full" label="First Given Name" placeholder="Example:John" name="FirstGivenName" v-model="custData.firstGivenName" />
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <ValidationProvider name="Family Name" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 }" v-slot="{ errors }" :custom-messages="customMessages">
                                <vs-input class="sm:w-full lg:w-1/1 w-full" label="Family Name" placeholder="Example:Lewis" name="FamilyName" v-model="custData.familyName" />
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <vs-input class="w-full" placeholder="Example:Jo" label="Other Given Names" name="OtherGivenNames" v-model="custData.otherGivenNames" />
                        </div>
                        <div class="vx-col">
                            <div class="divLabel">Date Of Birth</div>
                            <ValidationProvider name="Date Of Birth" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.dateofBirth" format="YYYY-MM-DD" type="date" valueType="YYYY-MM-DD" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <h5 class="text-secondary">Case Information</h5>
                    <vs-divider />
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <ValidationProvider name="File Number" :rules="{  required: true, max: 200 }" v-slot="{ errors }" :custom-messages="customMessages">
                                <vs-input class="w-full" label="File Number" placeholder="Example:1001/2022" name="FileNumber" v-model="custData.fileNumber" />
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <ValidationProvider class="w-full" name="clearancetype" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <div class="divLabel">Clearance Type</div>
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="clearancetype" v-model="custData.clearanceType" placeholder="Select">
                                    <vs-select-item :key="index" :value="item.id +'|'+ item.clearanceType" :text="item.clearanceType" v-for="(item, index) in clearanceTypes" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <ValidationProvider class="w-full" name="level" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <div class="divLabel">Level</div>
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="level" v-model="custData.level" placeholder="Select">
                                    <vs-select-item :key="index" :value="item.id + '|' + item.level" :text="item.level" v-for="(item, index) in levels" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <ValidationProvider class="w-full" name="clearancestatus" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <div class="divLabel">Clearance Status</div>
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="ClearanceStatusId" v-model="custData.clearanceStatusId" placeholder="Select">
                                    <vs-select-item :key="index" :value="item.id" :text="item.clearanceStatus" v-for="(item, index) in clearanceStatus" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <ValidationProvider class="w-full" name="department" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <div class="divLabel">Department Type</div>
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="ClientDepartment" v-model="custData.clientDepartment" placeholder="Select">
                                    <vs-select-item :key="index" :value="item.id +'|'+ item.clientDepartment " :text="item.clientDepartment" v-for="(item, index) in departments" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <vs-input disabled label="CSID" class="w-full" placeholder="CSID" name="VetteeId" v-model="custData.vetteeId" />
                        </div>
                        <div class="vx-col">
                            <ValidationProvider class="w-full" name="CaseOfficerId" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <div class="divLabel">Case Officer</div>
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="CaseOfficerId" v-model="custData.caseOfficerId" placeholder="Select">
                                    <vs-select-item :key="index" :value="item.id" :text="item.displayName" v-for="(item, index) in caseOfficers" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <h5 class="text-secondary">Other Information</h5>
                    <vs-divider />
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <ValidationProvider class="w-full" name="LocationType" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <div class="divLabel">Location Type</div>
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="LocationType" v-model="custData.locationType" placeholder="Select">
                                    <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in locationType" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <div class="divLabel">Location Notes</div>
                            <ValidationProvider name="Location Notes" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 }" v-slot="{ errors }" :custom-messages="customMessages">
                                <vs-textarea class="w-full" placeholder="Location Notes" name="LocationNotes" v-model="custData.locationNotes" />
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <div class="divLabel">Request Received</div>
                            <ValidationProvider name="Request Received" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.requestReceived" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <div class="divLabel">Required Return Date</div>
                            <ValidationProvider name="Required Return Date" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.requiredReturnDate" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <div class="divLabel">Priority Due Date</div>
                            <ValidationProvider name="Priority DueDate" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.priorityDueDate" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <div class="divLabel">Priority Met:</div>
                            <ValidationProvider class="w-full" name="Priority Met" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <vs-radio v-model="custData.priorityMet" vs-name="PriorityMet" v-bind:vs-value="true">Yes</vs-radio>
                                <vs-radio v-model="custData.priorityMet" class="ml-1" vs-name="PriorityMet" v-bind:vs-value="false">No</vs-radio>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>

                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <div class="divLabel">Extension ReturnDate</div>
                            <ValidationProvider name="Extension ReturnDate" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.extensionReturnDate" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <div class="divLabel">Extension Approved:</div>
                            <ValidationProvider class="w-full" name="Extension Approved" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <vs-radio v-model="custData.extensionApproved" vs-name="ExtensionApproved" v-bind:vs-value="true">Yes</vs-radio>
                                <vs-radio v-model="custData.extensionApproved" class="ml-1" vs-name="ExtensionApproved" v-bind:vs-value="false">No</vs-radio>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <div class="divLabel">NPHC In</div>
                            <ValidationProvider name="NPHC In" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.nphcin" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <ValidationProvider class="w-full" name="Nphcresult" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <div class="divLabel">NPHC Result</div>
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="Nphcresult" v-model="custData.nphcresult" placeholder="Select">
                                    <vs-select-item :key="index" :value="item" :text="item" v-for="(item, index) in NPHCResult" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col">
                            <div class="divLabel">Completed</div>
                            <ValidationProvider name="Completed" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.completed" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <div class="divLabel">Invoiced</div>
                            <ValidationProvider name="Invoiced" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.invoiced" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="vx-col">
                            <div class="divLabel">Date Of Disposal</div>
                            <ValidationProvider name="Date Of Disposal" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker v-model="custData.dateOfDisposal" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="vx-row mb-2">
                        <div class="vx-col w-full">
                            <div class="divLabel">Reason Over Benchmark</div>
                            <ValidationProvider name="Reason Over Benchmark" :rules="{ regex: /^[a-zA-Z0-9 -]+$/, required: true, max: 200 }" v-slot="{ errors }" :custom-messages="customMessages">
                                <vs-textarea class="w-full" placeholder="Reason Over Benchmark" name="ReasonOverBenchmark" v-model="custData.reasonOverBenchmark" />
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <vs-divider />
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <vs-button class="mr-3 mb-2" @click.prevent="saveCustomer">Submit</vs-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </vx-card>

    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="4" class="pr-3" v-if="isComments">
        <vx-card title="Comments" class="m-2">
            <comments :customerId="selectedCustomerId"></comments>
        </vx-card>
    </vs-col>

</vs-row>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {
    departmentData
} from "../../store/api/department";
import {
    ClearanceData
} from "../../store/api/clearanceType";
import {
    customerData
} from "../../store/api/customer";

import "vue2-datepicker/index.css";
import {
    userData
} from "../../store/api/user";
import {
    customMessages
} from "../../filters/validationmessage";

import comments from './ViewComments.vue'
import VxCard from '../../components/vx-card/VxCard.vue';

export default {
    data() {
        return {
            successMesage: "",
            actionTitle: "",
            priorityMet: null,
            departments: [],
            clearanceTypes: [],
            levels: [],
            clearanceStatus: [],
            caseOfficers: [],
            titles: [],
            customMessages,
            locationType: ["High", "Capital", "Other"],
            NPHCResult: ["IR", "NDCO", "DCO"],
            custData: {
                firstGivenName: "",
                familyName: "",
                fileNumber: ""
            },
            selectedCustomerId: null,
            isComments: false,
            customerCardWidth: 0
        };
    },
    beforeMount() {},
    components: {
        DatePicker,
        comments,
        VxCard
    },
    computed: {},
    async created() {
        if (this.$route.path.includes("edit")) {
            await this.updateEditWinData(this.$route.params.id);
        } else {
            this.customerCardWidth = 12;
            this.successMesage = "Added";
            this.actionTitle = "Add Customer";
        }
        await this.loadDepartments();
        await this.loadClearanceType();
        await this.loadLevel();
        await this.loadClearanceStatus();
        await this.loadCaseOfficers();
        await this.loadTitle();

    },
    methods: {
        async updateEditWinData(id) {
            this.selectedCustomerId = id;
            this.getCustomerDatabyId(this.selectedCustomerId);
            this.successMesage = "Update";
            this.actionTitle = "Edit Customer";
            this.isComments = true;
            this.customerCardWidth = 8;
        },

        async loadDepartments() {
            this.departments = [];
            this.departments = await departmentData.getAllDepartment();
        },
        async loadClearanceType() {
            this.clearanceTypes = [];
            this.clearanceTypes = await ClearanceData.getAllClearanceType();
        },
        async loadClearanceStatus() {
            this.clearanceStatus = [];
            this.clearanceStatus = await customerData.getAllClearanceStatus();
        },
        async loadLevel() {
            this.levels = [];
            this.levels = await customerData.getAllLevel();
        },
        async loadTitle() {
            this.titles = [];
            this.titles = await customerData.geAllTitle();
        },
        async loadCaseOfficers() {
            this.caseOfficers = [];
            //this.caseOfficers = await userData.searchUser();
            this.caseOfficers = await userData.getCaseOfficer();
        },
        async saveCustomer() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            let apiResult = await customerData.createCustomer(this.custData);

            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center",
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Customer " + this.successMesage,
                color: "success",
                position: "top-center",
            });
            
            await this.updateEditWinData(apiResult.data);
            this.$router.push(`/admin/Customers/edit/${apiResult.data}`);
            
        },
        async goBack() {
            this.$router.push("/admin/Customers");
        },
        async getCustomerDatabyId(id) {
            this.custData = await customerData.getCustomerById(id);
        }

    },
};
</script>

<style>
.cardx {
    margin: 5px;
}

.cardc {
    padding-right: 10px;
}

.divLabel {
    padding-left: 5px;
    padding-top: 5px;
    font-size: .85rem;
    color: rgba(0, 0, 0, .6);
}
</style>
